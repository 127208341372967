
    .practical-container {
        height: calc(100% - 40px);
        background: #F2F2F2;
        padding: 20px 0;
        .practical-content {
            height: 100%;
            display: flex;
            flex-direction: column;
            width: 1200px;
            margin: 0 auto;
        }
    }
    ::v-deep .el-breadcrumb {
        margin-bottom: 20px;
        .el-breadcrumb__inner {
            color: #000;
        }
    }
    .practical-list {
        display: flex;
        flex: 1;
        flex-direction: column;
        background-color: #fff;
    }
    .course-list {
        margin: 20px;
    }
    .pages-center {
        margin-bottom: 20px;
    }
    .student-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 78px;
        margin: 0 20px;
        border-bottom: 1px solid #EEEEEE;
        .header-left {
            display: flex;
            justify-content: start;
            align-items: center;
            .avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 48px;
                height: 48px;
                margin-right: 10px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 50%;
                }
            }
            .student-name {
                font-size: 24px;
            }
        }
        .header-right {
            font-size: 18px;
            color: #409EFF;
            cursor: pointer;
            i {
                margin-right: 10px;
            }
        }
    }
    .trend-content {
        position: relative;
        margin: 0 20px;
        height: 300px;
    }
    .table-item {
        display: flex;
        flex-direction: column;
        height: 390px;
        margin: 10px 20px;
        .item-text {
            font-size: 16px;
            margin-bottom: 10px;
        }
        .noSignIn {
            color: #FF0000;
        }
    }
